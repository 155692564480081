<template>
  <div class='bodyAuth'>
    <div class="auth">
      <div class='pulseLogo'>
        <a href='/'><img src='../assets/cryptoPulseLogo.svg' style='width:170px;'/></a>
      </div>
      <p style='font-size: 24px; font-family: "Proxima Nova Bold"; margin:15px;'>Авторизация</p>
      <div class='authInput'>
        <input v-model='login' placeholder='Логин'/>
      </div>
      <div class='authInput'>
        <input v-model='password' placeholder='Пароль' type='password' id='password'/>
        <img v-if='!showPassword' src='../assets/eye-show.svg' style='width:24px;' @click='showPasswordButton'/>
        <img v-else src='../assets/eye-hide.svg' style='width:24px;' @click='showPasswordButton'/>
      </div>
      <div class='authButton' @click='authorization'>
        Вход
      </div>
      <div class='regButton'>
        <p style='color: #0000005C;'>Еще нет аккаунта? <a href='/register' style='color: black; font-weight: 700;'>Зарегистрируйтесь</a></p>
      </div>
    </div>
  </div>
  
</template>
<script>
// @ is an alias to /src
import axios from 'axios'
import { useToast } from 'vue-toastification'
export default {
  name: 'AuthPage',
  data() {
    return {
      login: "",
      password: "",
      showPassword: false
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  methods: {
    showPasswordButton() {
      this.showPassword = !this.showPassword
      let pass = document.getElementById('password')
      if (this.showPassword) pass.type = 'text'
      else pass.type='password'
    },
    authorization() {
      axios.post('https://jsbros.codes/api/auth/login', {
        login: this.login,
        password: this.password
      })
      .then(res => {
        if (res.status == 201) {
          this.$store.commit('setUserData', res.data.result)
          this.toast.success("Вы успешно вошли")
          console.log(res.data)
          window.location.href = "/dashboard"
        }
      })
      .catch(err => {
        this.toast.error(err.response.data.message, {timeout: 2000})
      })
    }
  }
}
</script>
<style scoped>
.bodyAuth {
  background-image: url('../assets/firstBlock.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 30vh;
  width:100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth {
  background-color: white;
  color: black;
  border-radius: 15px;
  width:25%;
  padding:30px;
  display: flex;
  flex-direction: column;
}
.authInput {
  border: 1px solid #0000005C;
  display: flex;
  border-radius:12px;
  padding: 5px;
  margin-bottom: 3%;
  height: 50px;
}
.authInput img {
  cursor: pointer;
}
.authInput input {
  border: 1px solid transparent;
  outline: none;
  padding-left: 15px;
  font-size: 18px;
  width: 90%;
  height: 100%;
}
.authInput input::placeholder {
  color: #0000005E;
}
.authButton {
  background-color: #000000;
  border-radius: 7px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 50px;
  cursor: pointer;
}
.regButton {
  margin-top:15px;
}
@media(max-width:968px) {
  .auth {
    width: 90%;
  }
  .bodyAuth {
    background-position: 0vw 50vh;
    background-size:150%;
  }
}
</style>
