<template>
  <div class='bodyRegister'>
    <div class="register">
      <div class='pulseLogo'>
        <a href='/'><img src='../assets/cryptoPulseLogo.svg' style='width:170px;'/></a>
      </div>
      <p style='font-size: 24px; font-family: "Proxima Nova Bold"; margin:15px;'>Регистрация</p>
      <div class='registerInput'>
        <input v-model='login' placeholder='Ваш логин'/>
      </div>
      <div class='registerInput'>
        <input v-model='email' placeholder='E-mail'/>
      </div>
      <div class='registerInput'>
        <input v-model='password' placeholder='Пароль' type='password' id='password'/>
        <img v-if='!showPassword' src='../assets/eye-show.svg' style='width:24px;' @click='showPasswordButton'/>
        <img v-else src='../assets/eye-hide.svg' style='width:24px;' @click='showPasswordButton'/>
      </div>
      <div class='registerInput'>
        <input placeholder='Повторите пароль' type='password' id='password-repeat'/>
        <img v-if='!showPasswordRepeat' src='../assets/eye-show.svg' style='width:24px;' @click='showPasswordButton'/>
        <img v-else src='../assets/eye-hide.svg' style='width:24px;' @click='showPasswordButton'/>
      </div>
      <div class='registerInput'>
        <input v-model='promo' placeholder='Промокод (не обязательно)'/>
      </div>
      <div class='registerButton' @click='registration'>
        Регистрация
      </div>
      <p style='font-size: 16px;color:#0000005C; margin: 10px;'>Нажимая кнопку вы соглашаетесь с правилами по обработке персональных данных</p>
      <div class='authButton'>
        <p style='color: #0000005C;'>Уже есть аккаунт? <a href='/auth' style='color: black; font-weight: 700;'>Авторизуйтесь</a></p>
      </div>
    </div>
  </div>
  
</template>
<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'RegisterPage',
  data() {
    return {
      login: "",
      password: "",
      email: "",
      promo: "",
      showPassword: false,
      showPasswordRepeat: false
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  methods: {
    showPasswordButton(event) {
      let passwordType = event.target.previousSibling.id
      let pass = document.getElementById(passwordType)
      if (passwordType == "password") {
        this.showPassword = !this.showPassword
        if (this.showPassword) pass.type = 'text'
        else pass.type='password'
      }
      else {
        this.showPasswordRepeat = !this.showPasswordRepeat
        if (this.showPasswordRepeat) pass.type = 'text'
        else pass.type='password'
      }
    },
    registration() {
      axios.post('https://jsbros.codes/api/auth/register', {
        login: this.login,
        email: this.email,
        password: this.password,
        promocode: this.promo
      })
      .then(res => {
        console.log(res)
        window.location.href = "/auth"
      })
      .catch(err => {
        this.toast.error(err.response.data.message, {timeout: 2000})
      })
    }
  }
}
</script>
<style scoped>
.bodyRegister {
  background-image: url('../assets/firstBlock.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 30vh;
  width:100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register {
  background-color: white;
  color: black;
  border-radius: 15px;
  width:25%;
  padding:30px;
  display: flex;
  flex-direction: column;
}
.registerInput {
  border: 1px solid #0000005C;
  display: flex;
  border-radius:12px;
  padding: 5px;
  margin-bottom: 3%;
  height: 50px;
}
.registerInput img {
  cursor: pointer;
}
.registerInput input {
  border: 1px solid transparent;
  outline: none;
  padding-left: 15px;
  font-size: 18px;
  width: 90%;
  height: 100%;
}
.registerInput input::placeholder {
  color: #0000005E;
}
.registerButton {
  background-color: #000000;
  border-radius: 7px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 50px;
  cursor: pointer;
}
.authButton {

}
@media(max-width:968px) {
  .register {
    width: 90%;
  }
}
</style>
