<template>
  <div style='width:100vw; height:100vh;'>
    <highcharts style='height:100vh;' :options="chartOptions" :constructor-type="'stockChart'"></highcharts>
    <button @click='loadCandles'></button>
    <button @click='testDelete'>BEBEB</button>
  </div>
  
  
</template>
<script>
import axios from 'axios'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
stockInit(Highcharts)

export default {
    name: 'App',
    components: {
        
    },
    data() {
      return {
        chartOptions: {
          height: null,
          plotOptions: {
              candlestick: {
                  color: 'pink',
                  lineColor: 'red',
                  upColor: 'lightgreen',
                  upLineColor: 'green'
              }
          },
          series: [{
            type: 'candlestick',
            data: []
          }]
        }
        }
    },
    mounted() {
      this.removeWatermark()
      console.log(123)
    },
    methods: {
      removeWatermark() {
        console.log(123)
        let t = document.querySelector('.highcharts-credits')
        console.log(t)
        t.remove()
        // let temp = [
        //       Date.now(),
        //       62231.00555111,
        //       62221.00555111,
        //       62252.00555111,
        //       62240.00555111
        //     ]
        // this.chartOptions.series[0].data.push(temp)
        // console.log(this.chartOptions.series[0])

      },
      loadCandles() {
        axios.get('https://jsbros.codes/api/bet/candles/BTC', {
        start: "1728218631675",
        end: "1728218641675",
        headers: {
          Authorization: 'Bearer ' + this.$store.state.user.token
        }
        })
        .then(res => {
          let temp = {}
          for(let i = 0;i<10000; i++){
            temp = [
              res.data.result[i].timestamp,
              res.data.result[i].open,
              res.data.result[i].high,
              res.data.result[i].low,
              res.data.result[i].close
            ]
            this.chartOptions.series[0].data.push(temp)
          }
          console.log(this.chartOptions.series[0])
        })
        
      }
    }
};

</script>